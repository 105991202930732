<!--
 * @Description: 车辆用户管理系统 投诉管理 投诉管理 cuComplain
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 15:38:52
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="投诉人电话">
              <el-input v-model="searchForm.peopleNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="泊位号">
              <el-input v-model="searchForm.parkingNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.carNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary">查 询</el-button>
        <el-button type="info"
                   style="margin-left:17px">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>投诉管理</span>
        <el-button class="tableTitleButton"
                   type="info">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="12">
                    <el-button @click="handleClick(scope.row)"
                               type="text"
                               size="medium">查看</el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button type="text"
                               size="medium">编辑</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumnList: [
        {
          prop: 'parkingName',
          label: '级别',
        },
        {
          prop: 'carNumber',
          label: '工单类别',
        },
        {
          prop: 'getIntoTime',
          label: '投诉人电话',
        },
        {
          prop: 'leaveTime',
          label: '投诉日期',
        },
        {
          prop: 'parkingTime',
          label: '泊位号',
        },
        {
          prop: 'amountMoney',
          label: '车牌号',
        },
        {
          prop: 'payMethods',
          label: '投诉来源',
        },
        {
          prop: 'deviceType',
          label: '设备类型',
        },
        {
          prop: 'registrant',
          label: '登记人',
        },
        {
          prop: 'Handler',
          label: '处理人',
        },
        {
          prop: 'orderStatus',
          label: '工单状态',
        },
      ],
      tableList: {
        list: [
        ],
      },
      searchForm: {
        orderNumber: '',
        peopleNumber: '',
        parkingNumber: '',
        carNumber: '',
      },
      total: 10,
      options: [
        { label: '路段一临时停车', value: 1 },
        { label: '路段二临时停车', value: 2 },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  //方法集合
  methods: {
    handleClick (val) {
      console.log(val)
    },
    getList () {
      console.warn('获取内容')
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.search_column2 {
  margin-top: 20px;
}
</style>

